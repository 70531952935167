import { createStore } from "vuex";

export default createStore({
  state: {
    loader: 0,
    en:{
      loader_text: "Loading Please wait",
    },
    ar:{
      loader_text: "جاري التحميل الرجاء الإنتظار",
    },

    // baseApiURL: "https://fattouhcrypto.com/apis/",
    baseApiURL: "https://fattouhcrypto.com/api/",
    baseURL: "https://fattouhcrypto.com/",
    baseImageURL: "https://fattouhcrypto.com/images/",
    isAuth:false,
    loader:0,
    wallet:0,
    banners:[],
    selectedCurrencyRate:1,
    selectedCurrencyCode:'$',
    selectedCurrency:{},
    configurations:{
      // email:null,
      // about_desc:null,
      // about_title:null,
      // whatsapp_number:null,
      // instagram_url:null,
      // homepage_banner:null,
      // homepage_banner2:null,
      // homepage_banner3:null,
      // homepage_banner4:null,
      // homepage_banner5:null,
    }
    },
  getters: {},
  mutations: {
    clearEmployeeScheduleInfo(state) {
      state.employee_schedule_info = null;
    },
  },
  actions: {},
  modules: {},
});
