<template>
    <div class="loader_main_overlay" v-if="$store.state.loader">
        <div class="loader_content">
            <div class="loader_text">{{ $store.state[$i18n.locale].loader_text }}</div>
            <div class="lds-dual-ring"></div>
        </div>
    </div>
    
    <router-view></router-view>
    <div v-if="$store.state.loader==1" class=" pf bgb50 ov z9000">
      <!-- <span class="loader cc"></span>     -->
      <img src="@/assets/img/loader.png"  style="width:50px" alt="" class="cc loaderImg">

</div>
    </template>
    
    <script>
    export default {
        name: "EmptyLayout",
        created(){
    // var config= localStorage.getItem('_FCc');
    // if(config ==null || config==0 || config==0){
    //   const formDataU = new FormData();
    //   formDataU.append('action', 'configurations');
    //   this.axios.post(this.$store.state.baseApiURL + "requests.php", formDataU,{
    //   }).then((response) => {
    //     this.$store.state.configurations=response.data.data
    //     localStorage.setItem('_FCc', JSON.stringify(response.data.data));
    //   })
    //   .catch((e) => {});
    // }
    // else{
    //   this.$store.state.configurations=JSON.parse(config)
    // }

      
  },
    }
    
    </script>
    <style scoped>
    .slmenubtn {
        margin-right: 14px;
    }
    .logoutcsaz {
        position: absolute;
        z-index: 9;
        left: 3%;
        top: 3%;
        font-size: 18px;
        color: #5456a6;
        cursor: pointer;
        font-weight: 600;
    }
    </style>