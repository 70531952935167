import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      Login:'Login',
      Logout:'Logout',
      Home:'Home',
      My_Order:'My Order',
      Add_Credit:'Add Credit',
      My_Wallet:'My Wallet',
      Security:'Security',
      About:'About',
      Contact_Us:'Contact Us',
      Menu:'Menu',
      My_Profile:'My_Profile',
      Arabic:'Arabic',
      English:'English',
      Lang:'English',
      Amount_is_required:'Amount is required',
      Please_Enter_a_valid_value:'Please Enter a valid value',
      Payment_is_under_Proccessing_It_may_takes_few_hours:'Add credit is under Proccessing \n It may takes few hours',
      Add_credit_Failed_Please_Try_Again:'Add credit Failed Please Try Again',
      Missing_some_fileds:'Missing some fileds',
      Receiver:'Receiver',
      Amount:'Amount',
      Currency:'Currency',
      Receipt_Image:'Receipt Image',
      Receipt_image_is_required:'Receipt image is required',
      Search:'Search',
      Sorry_there_is_no_result:'Sorry, there is no result',
      Ful_Name:'Full Name',
      Email:'Email',
      phone:'phone',
      Message:'Message',
      Fullname_is_required:'Fullname is required',
      Email_is_required:'Email is required',
      Password_is_required:'Password is required',
      Phone_is_required:'Phone is required',
      Message_is_required:'Message is required',
      Message_Sent_successfully:'Message Sent successfully',
      Failed_to_send_message_Please_Try_Again:'Failed to send message Please Try Again',
      Failed_to_fetch_data_please_try_again:'Failed to fetch data \n please try again',
      Password:'Password',
      Please_Enter_the_OTP_code_send_to_your_email:'Please Enter the OTP code send to your email',
      OTP:'OTP',
      Submit:'Submit',
      Login_Successful:'Login Successful',
      wrong_otp:'wrong otp',
      Failed_to_verfiy_account_Please_Try_Again:"Failed to verfiy account Please Try Again",
      Login_failed_Please_Try_Again:"Login failed, please try again",
      Dont_Have_Account:"Don't Have Account",
      Register_Now:"Register Now",
      The_email_and_password_doesnt_match:"The email/phone and password doesn't match",
      This_email_doesnt_has_account:"This email doesn't has account",
      Cancel:"Cancel",
      Tag_ID:"Tag ID",
      Quantity:"Quantity",
      Buy:"Buy",
      Maximum_avalabile_quantity_in_stock_is:"Maximum avalabile quantity in stock is",
      Minimum_quantity_in_is:"Minimum quantity in is",
      Game_ID_is_required:"Game ID is required",
      Quantity_is_required:"Quantity is required",
      Please_number_should_be_between:"Please number should be between",
      Total:"Total",
      and:" and ",
      Product_ordered_Successfully:"Product ordered Successfully",
      Insufficient_amount_in_the_wallet:"Insufficient amount in the wallet",
      Failed_to_orderd_this_service_Please_Try_Again:"Failed to orderd this service, please try again",
      Approved:"Approved",
      New:"New",
      Rejected:"Rejected",
      Clear:"Clear",
      Filter:"Filter",
      Sorry_there_is_no_Order:"Sorry there is no Order",
      Order_Details:"Order Details",
      Order_Id:"Order Id",
      Product:"Product",
      Quantity:"Quantity",
      Total_Price:"Total Price",
      Date:"Date",
      Clear_Filter:"Clear Filter",
      Last_day:"Last day",
      Last_7_days:"Last 7 days",
      Last_30_days:"Last 30 days",
      Product_ID:"Product ID",
      Product_Name:"Product Name",
      Rejected_Refund:"Rejected (Refund)",
      Your_Balance:'Your Balance',
      Total_Purchases:'Total Purchases',
      Received:'Received',
      Income:'Income',
      Outcome:'Outcome',
      Sorry_there_is_no_Transaction:'Sorry there is no Transaction',
      Transaction_Details:'Transaction Details',
      Profile_Updated_Successfully:"Profile Updated Successfully",
      Profile_Updated_Failed_Please_Try_Again:"Profile updated failed, please try again",
      First_Name:"First Name",
      Last_Name:"Last Name",
      Firstname_is_required:"First Name is required",
      Lastname_is_required:"Last Name is required",
      My_Profile:"My Profile",
      Amount_in_usd:"Amount in $",
      Confirm_Password_is_required:"Confirm Password is required",
      New_Password_is_required:"New Password is required",
      Old_Password_is_required:"Old Password is required",
      The_password_should_be_at_least_6_character:"The password should be at least 6 character",
      The_passwords_dosent_match:"The passwords dosen't match",
      Password_Updated_Successfully:"Password Updated Successfully",
      Password_Update_Failed_Please_Try_Again:"Password Update Failed \n Please Try Again",
      Old_password_is_incorrect:"Old password is incorrect",
      Old_Password:"Old Password",
      New_Password:"New Password",
      Confirm_Password:"Confirm Password",
      Please_fill_all_required_fileds:"Please fill all required fileds",
      Change_Password:"Change Password",
      Forget_Password:"Forget Password",
      Reset_link_sent:"Reset password link sent to your email if it is valid",
      Reset_link_sent_failed:"Reset link sending failed, \n please try again",
      My_group:"My Group",
      Country:"Country",
      Country_is_required:"Country is required",
      Offer:"Offer",
      normal_Login:"This email is not sgin up via google please login via email and password not google",
      google_log:"This email is registered via google sgin up please login via google",
      Failed_to_create_account_Please_Try_Again:"Failed to create account, Please Try Again",
      Email_address_has_already_been_taken:"Email address has already been taken",
      
    },
    'ar': {
      Login:'تسجيل دخول',
      Logout:'تسجيل خروج',
      Home:'الرئيسية',
      My_Order:'طلباتي',
      Add_Credit:'إضافة رصيد',
      My_Wallet:'محفظتي',
      Security:'الأمان',
      About:'من نحن',
      Contact_Us:'إتصل بنا',
      Menu:'القائمة',
      My_Profile:'حسابي',
      Arabic:'العربية',
      English:'الأجنبية',
      Lang:'العربية',
      Amount_is_required:'الرجاء إدخال المبلغ',
      Please_Enter_a_valid_value:'الرجاء إدخال مبلغ صحيح',
      Payment_is_under_Proccessing_It_may_takes_few_hours:'عملية إضافة الرصيد تحت المعالجة \n قد تحتاج إلى عدة ساعات',
      Add_credit_Failed_Please_Try_Again:'فشلت عملية إضافة الرصيد، الرجاء إعادة المحاولة',
      Missing_some_fileds:'بعض الخانات فارغة',
      Receiver:'المستلم',
      Amount:'المبلغ',
      Currency:'العملة',
      Receipt_Image:'صورة الإيصال',
      Receipt_image_is_required:'الرجاء تحميل صورة الإيصال',
      Search:'بحث',
      Sorry_there_is_no_result:'عذراً، لا يوجد نتائج',

      Ful_Name:'الاسم الكامل',
      Email:'البريد الإلكتروني',
      phone:'رقم التليفون',
      Message:'الرسالة',
      Fullname_is_required:'الرجاء إدخال الاسم الكامل',
      Email_is_required:'الرجاء إدخال البريد الإلكترني',
      Password_is_required:'الرجاء إدخال كلمة لبسر ',
      Phone_is_required:'الرجاء إدخال رقم الهاتف',
      Message_is_required:'الرجاء إدخال الرسالة',
      Message_Sent_successfully:'تم إرسال الرسالة بنجاح',
      Failed_to_send_message_Please_Try_Again:'فشل إرسال الرسالة، الرجاء إعادة المحاولة',
      Failed_to_fetch_data_please_try_again:'فشل تحميل المعلومات \n الرجاء إعادة المحاولة',
      Password:'كلمة المرور',
      Please_Enter_the_OTP_code_send_to_your_email:'الرجاء إدخال الرمز المرسل لك عبر بريد الإلكتروني',

      OTP:'الرمز',
      Submit:'إرسال',
      Login_Successful:'تم تسجيل الدخول بنجاح',
      wrong_otp:'الرمز خاطئ',
      Failed_to_verfiy_account_Please_Try_Again:"فشل التحقق من الحساب، يرجى المحاولة مرة أخرى",
      Login_failed_Please_Try_Again:"فشل تسجيل الدخول، الرجاء إعادة المحاولة",
      Dont_Have_Account:"لا تمتلك حساب",
      Register_Now:"إنشاء حساب الآن",
      The_email_and_password_doesnt_match:"البريد الإلكتروني أو الهاتف وكلمة السر غير متطابقين",
      This_email_doesnt_has_account:"لا يوجد حساب لهذا البريد الإلكتروني",
      Cancel:"إلغاء",
      Tag_ID:"Tag ID",
      Quantity:"الكمية",
      Buy:"شراء",
      Maximum_avalabile_quantity_in_stock_is:"الكمية القصوى المسموح بها",
      Minimum_quantity_in_is:"كيمة الحد الأدنى",
      Game_ID_is_required:"الرجاء إدخال رقم اللاعب في اللعبة",
      Quantity_is_required:"الرجاء إدخال الكمية",
      Please_number_should_be_between:"الرجاء إدخال رقم بين",
      Total:"المجموع",
      and:" و ",
      Product_ordered_Successfully:"تمت ‘رسال الطلب بنجاح",
      Insufficient_amount_in_the_wallet:"المبلغ غير كافي في المحفظة",
      Failed_to_orderd_this_service_Please_Try_Again:"فشلت عملية الشراء، الرجاء إعادة المحاولة",
      Approved:"مقبول",
      New:"جديد",
      Rejected:"مرفوض",
      Clear:"مسح",
      Filter:"فلتر",
      Sorry_there_is_no_Order:"عذراً، لا يوجد طلبات",
      Order_Details:"تفاصيل الطلب",
      Order_Id:"رقم الطلب",
      Product:"المنتج",
      Quantity:"الكمية",
      Total_Price:"المجموع",
      Date:"التاريخ",
      Clear_Filter:"حذف الفلتر",
      Last_day:"اخر يوم",
      Last_7_days:"اخر سبعة أيام",
      Last_30_days:"اخر ثلاثين يوم",
      Product_ID:"رقم المنتج",
      Product_Name:"اسم المنتج",
      Rejected_Refund:"مرفوض (تم الإسترداد)",
      Your_Balance:'الرصيد',
      Total_Purchases:'مجموع الدفعات',
      Received:'مجموع الوارد',
      Income:'صادر',
      Outcome:'وارد',
      Sorry_there_is_no_Transaction:'عذرا، لا يوجد تحويلات',
      Transaction_Details:'تفاصيل التحويل',
      Profile_Updated_Successfully:"تم تعديل الحساب بنجاح",
      Profile_Updated_Failed_Please_Try_Again:"قشل تعديل الحساب، الرجاء إعادة المحاولة",
      First_Name:"الاسم الأول",
      Last_Name:"اسم العائلة",
      Firstname_is_required:"الرجاء إدخال الاسم الأول",
      Lastname_is_required:"الرجاء إدخال اسم العائلة",
      My_Profile:"حسابي",
      Amount_in_usd:"المبلغ بالدولار",
      Confirm_Password_is_required:"الرجاء تأكيد كلمة المرور",
      New_Password_is_required:"الرجاء إدخال كلمة المرور الجديدة",
      Old_Password_is_required:"الرجاء إدخال كلمة المرور القديمة",
      The_password_should_be_at_least_6_character:"يجب أن تتكون كلمة المرور من ستة أحرف على الأقل",
      The_passwords_dosent_match:"كلمة المرور لا تتطابق",
      Password_Updated_Successfully:"تم تغير كلمة المرور بنجاح",
      Password_Update_Failed_Please_Try_Again:"فشل تغير كلمة المرور \n الرجاء إعادة المحاولة",
      Old_password_is_incorrect:"كلمة المرور القديمة غير صحيحة",
      Old_Password:"كلمة المرور القديمة",
      New_Password:"كلمة المرور الجديدة ",
      Confirm_Password:"تأكيد كلمة المرور",
      Please_fill_all_required_fileds:"الرجاء إدخال جميع الخانات الإجبارية",
      Change_Password:"تغير كلمة المرور",
      Forget_Password:"نسيت كلمة المرور",
      Reset_link_sent:"لقد تم إرسال رابط إعادة ضبط كلمة المرور إلى بريدك الإلركتروني في حال كان صحيح",
      Reset_link_sent_failed:"فشل إرسال رابط إعادة ضبط كلمة المرور \n  الرجاء إعادة المحاولة",
      My_group:"مجموعتي",
      Country:"البلد",
      Country_is_required:"الرجاء إختيار البلد",
      Offer:"عرض",
      normal_Login:"هذا البريد غير مسجل عن طريق جوجل الرجاء الدخول من خلال البريد الإلركتروني وكلمة المرور وليس جوجل",
      google_log:"هذا البريد الإلكتروني مسجل من خلال جوجل الرجاء تسجيل الدخول من خلال جوجل",
      Failed_to_create_account_Please_Try_Again:"فشل عملية إنشاء حساب، الرجاء إعادة المحاولة",
      Email_address_has_already_been_taken:"البريد الإلكتروني مسجل مسبقاً",
      

    },
  },
})
export default i18n

