<template class="">
    <div class="cw pf ov ba navBg sh10 bgw pv10 ph40 m_ph10" style="z-index: 100000000000 ;">
      <tbl_nm>
        <cl class="w50 m_w60 vm l fs0">
          <div class="g_nm wa  vm">
            <div class="round100 g_nm navItem pointer">
              <span class="cc mdi mdi-menu fs24 cw" @click="showSideBar()"></span>
              <div class="tooltip fs12">{{ $t('Menu') }}</div>
            </div>
          </div>
          <div class="g_nm wa  vm">
            <div class="round100 g_nm navItem pointer" style="width:25px; height:25px;">
              <span class="cc mdi mdi-google-translate fs20 cw" @click="changeLang()"></span>
              <div class="tooltip fs12">{{ $t('Lang') }}</div>
            </div>
          </div>
          <div class="g_nm wa  vm">
            <div class="round100 g_nm navItem pointer ">
              <span class="cc currencyDispalyDiv pointer fs16 lh24 cw" @click="ShowCurrency()">{{ $store.state.selectedCurrencyCode }}</span>
              <div class="ov ba ra fs15 currencyOptionMainDiv nw brdrGold">
                <div class="currencyOptionDiv p5" v-for="item in $store.state.currencies" @click="chnageCurrency(item)">{{ item.code }} - {{item.name}}</div>
              </div>
              <!-- <select>
                <option v-for="item in $store.state.currencies" :value="item.rate">{{ item.code }}</option>
              </select> -->
              <div class="tooltip fs12">{{ $t('curency') }}</div>
            </div>
          </div>
          <div v-if="$store.state.isAuth" class="g_nm wa  vm">
            <router-link :to="{ name: 'profile' }">
              <div class="round100 g_nm navItem pointer" style="width:25px;">
                <span class="cc mdi mdi-account fs20 cw"></span>
              <div class="tooltip fs12">{{ $t('My_Profile') }}</div>
              </div>
            </router-link>
          </div>

          <div v-if="!$store.state.isAuth" class="g_nm wa  vm">
            <router-link :to="{ name: 'login' }">
              <div class="round100 g_nm navItem  pointer" style="width:25px;">
                <span class="cc mdi mdi-login fs20 cw"></span>
                <div class="tooltip fs12">{{ $t('Login') }}</div>
              </div>
            </router-link>
          </div>
          <div v-if="$store.state.isAuth" class="g_nm wa  vm">
            <div class="round100 g_nm navItem pointer" @click="logout()" style="width:25px;">
              <span class="cc mdi mdi-login fs20 cw"></span>
              <div class="tooltip fs12">{{ $t('Logout') }}</div>
            </div>
          </div>
          <div v-if="$store.state.isAuth" class="g_nm wa  vm ph5 m_ph0">
            <div class="round4x g_nm navItem wallet pointer">
              <router-link :to="{ name: 'MyWallet' }">
                <span class=" mdi mdi-wallet vm fs20 cw"></span>
                <span class="ml5 fs16 vm">{{ $store.state.wallet*$store.state.selectedCurrencyRate }}{{ $store.state.selectedCurrencyCode }}</span>
              </router-link>
            </div>
          </div>
        </cl>
        <cl class="colSep pv5"></cl>
        <cl class="w50 m_w40 r m_c vm">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/img/logo300.png" alt="logo" class="w100" style="max-width: 250px;">
          </router-link>
        </cl>
      </tbl_nm>
    </div>
    <div class="pf ov ra sideBar" style="z-index:1000000000;">
      <div class="pt70 ph20 m_pt120 sideMenu pb40 oya" style="max-height:calc( 100vh -140px);">
        <div class="sideBarItem l">
            <router-link :to="{ name: 'home' }">
              <tbl_nm>
                <cl class="vm l" style="width: 30px;">
                  <span class="mdi mdi-home fs20 success"></span>
                </cl>
                <cl class="vm l lh40">{{ $t('Home') }}</cl>
              </tbl_nm>
            </router-link>
        </div>
        <div v-if="$store.state.isAuth" class="sideBarItem l">
          <router-link :to="{ name: 'Myorders' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-cart fs18 danger"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('My_Order') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div v-if="$store.state.isAuth" class="sideBarItem l" style="cursor: none;">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-account-group-outline fs18 info"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('My_group') }}: {{$cookies.get("_FCu").client_group.name}}</cl>
            </tbl_nm>
        </div>

        <div v-if="$store.state.isAuth" class="sideBarItem l">
          <router-link :to="{ name: 'AddCredit' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-wallet-plus fs20 primary"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('Add_Credit') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div v-if="$store.state.isAuth" class="sideBarItem l">
          <router-link :to="{ name: 'MyWallet' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-wallet fs20 warning"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('My_Wallet') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div v-if="$store.state.isAuth" class="sideBarItem l">
          <router-link :to="{ name: 'security' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-security fs20 success"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('Security') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div class="sideBarItem l">
          <router-link :to="{ name: 'about' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-message-alert  fs20 primary"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('About') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div class="sideBarItem l">
          <router-link :to="{ name: 'contactus' }">
            <tbl_nm>
              <cl class="vm" style="width: 30px;">
                <span class="mdi mdi-message-reply-text fs20 warning"></span>
              </cl>
              <cl class="vm l lh40">{{ $t('Contact_Us') }}</cl>
            </tbl_nm>
          </router-link>
        </div>
        <div class="sideBarItem l cw " v-if="$store.state.configurations?.email">
          <a href="mailto: info@fattouhcrypto.com" class="w100 oe nw">
            <p class="oe">
              <span class="mdi mdi-email fs20 info" style="width: 30px;"></span>
              <span class="pl10 w100 lh40 oe">{{ $store.state.configurations?.email }}</span>
            </p>
          </a>
        </div>

        <div class="mt20 fs0">
          <div class="g_nm ph5 fs40 primary vm" v-if="$store.state.configurations?.facebook_url"><a :href="$store.state.configurations?.facebook_url" target="_blank"><span class="lh30 mdi mdi-facebook pointer"></span></a></div>
          <div class="g_nm ph5 fs40 warning vm" v-if="$store.state.configurations?.instagram_url"><a :href="$store.state.configurations?.instagram_url" target="_blank"><span class="lh30 mdi mdi-instagram pointer"></span></a></div>
          <div class="g_nm ph5 fs40 success vm" v-if="$store.state.configurations?.whatsapp_number"><a :href="'https://wa.me/'+$store.state.configurations?.whatsapp_number+'?text=Hello%20FattouhCrypto'" target="_blank"><span class="lh30 mdi mdi-whatsapp pointer"></span></a></div>
        </div>
      </div>
    </div>
    

    <div class="pt70 m_pt90 mainDiv" >
      <router-view></router-view>
    </div>
    <footer style="--mainDivHeight: calc(100vh - 100px);">
      <div class="cw navBg pv10 ph40 m_ph10 fs14">
        Copyright © {{ currentYear }} , Powered by <a href="https://web-flames.com/" class="b">WebFlames</a> 
      </div>
    </footer>
    <div v-if="$store.state.loader==1" class=" pf bgb50 ov" style="z-index: 100000000001;">
      <!-- <span class="loader cc"></span>     -->
      <img src="@/assets/img/loader.png"  style="width:50px" alt="" class="cc loaderImg">
</div>
    </template>
    
<script>
import { googleLogout } from 'vue3-google-login'
export default {
  name: "UserLayout",
  data(){
    return{
      currentYear: new Date().getFullYear(),
      wallet:0,
    }
  },
  watch: {
    '$route'(to, from) {
      this.checkUser();
  },
  },
  created(){
    if (this.$cookies.isKey("_FClang")) {
      if(this.$cookies.get('_FClang')=='ar'){
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
      }
      else{
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
      }
    }
    else{
      const expirationTime =  5 * 60 * 60;
      this.$cookies.set("_FClang", this.$i18n.locale, expirationTime);
      
    }
    this.axios.get(this.$store.state.baseApiURL + "get-configurations",{
    }).then((response) => {
      this.$store.state.configurations=response.data.data[0]
    })
    .catch((e) => {});
    this.axios.get(this.$store.state.baseApiURL + "get-currencies",{
    }).then((response) => {
      this.$store.state.currencies=response.data.data
    })
    .catch((e) => {});
    this.checkUser();
    // Add click event listener to document
    document.addEventListener('click', (event) => {
      const clickedElement = event.target;

      // Check if clicked element is not within currencyDispalyDiv
      if (!clickedElement.closest('.currencyDispalyDiv')) {
        // Check if clicked element or any of its ancestors have class currencyOptionMainDiv
        if (!clickedElement.closest('.currencyOptionMainDiv')) {
          $('.currencyOptionMainDiv').removeClass('active');

        }
      }
    });
  },

  methods: {
    chnageCurrency(item){
      const expirationTime =  5 * 60 * 60;
      this.$cookies.set("_FCsc", (item), expirationTime);
      this.$store.state.selectedCurrency=item;
      this.$store.state.selectedCurrencyRate=item.rate;
      this.$store.state.selectedCurrencyCode=item.code;
      $('.currencyOptionMainDiv').removeClass('active');
    },
    ShowCurrency(){
      if($('.currencyOptionMainDiv').hasClass('active')){
        $('.currencyOptionMainDiv').removeClass('active');
      }
      else{
        $('.currencyOptionMainDiv').addClass('active');
      }

    },
    checkUser(){
      if (this.$cookies.isKey("_FCu")) {
        var _FCat=JSON.parse(this.$cookies.get('_FCat'));
        var _FCu=(this.$cookies.get('_FCu'));
        this.axios.get(this.$store.state.baseApiURL + "client/refresh-wallet",{
          headers: {'Authorization': `Bearer ${_FCat}`}
        }).then((response) => {
          this.disabledSubmit=false;
          var status= response.data.success
          if(status==1){
            var wallet=response.data.data;
            if(parseFloat(wallet.balance)<0.0001){wallet.balance=0;}
            else{wallet.balance=this.roundToFixed(wallet.balance)}
            if(wallet.balance!=_FCu.wallet.balance){
              this.$toast.open({message: "Wallet updated",type: 'success',duration:5000,position:'top-right',});
              _FCu.wallet=wallet;
              const expirationTime =  5 * 60 * 60;
              this.$cookies.set("_FCu", JSON.stringify(_FCu), expirationTime);
            }
          }
          this.$store.state.wallet=(wallet.balance);
        })
        .catch((e) => { this.disabledSubmit=false;});
      }
    },
    roundToFixed(number){
      const numberString = number.toString();
      const dotIndex = numberString.indexOf('.');
      if (dotIndex !== -1 && numberString.length > dotIndex + 2 + 1) {
        const truncatedString = numberString.substr(0, dotIndex + 2 + 1);
        return parseFloat(truncatedString);
      }
      return number;
    },
    showSideBar(){
      $('.sideBar').toggleClass('active')
    },
    logout(){
      localStorage.clear();
      this.$cookies.remove("_FCu");
      this.$cookies.remove("_FClang");
      this.$cookies.remove("_FCat");
      googleLogout();
      this.$store.state.isAuth=false;
      this.$router.push({name:'home'})
    },
    changeLang(){
      if(this.$i18n.locale=="ar"){
        this.$i18n.locale="en";
        $('body').removeClass('rtl');
        const expirationTime =  5 * 60 * 60;
        this.$cookies.set("_FClang", this.$i18n.locale, expirationTime);
      }
      else{
        this.$i18n.locale="ar";
        $('body').addClass('rtl');
        const expirationTime =  5 * 60 * 60;
        this.$cookies.set("_FClang", this.$i18n.locale, expirationTime);
      }
    }
      
  },   
}
</script>
<style>
  .tooltip {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s;
    white-space: nowrap;
  }

  .navItem:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
</style>