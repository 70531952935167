import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Select2 from "vue3-select2-component";
import vue3GoogleLogin from 'vue3-google-login';
import i18n from "./i18n";

import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/MyStyle.css";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import VueCookies from 'vue3-cookies'


const app = createApp(App);
app.component("Select2", Select2);
app.use(vue3GoogleLogin, {
  // clientId: '363169735438-rf02ja12esfntrsra22ju652upqe3bhr.apps.googleusercontent.com' //localhost
  clientId: '363169735438-jtrb0nc49ir5blbff00mlvr7o9rm2b0q.apps.googleusercontent.com' //online
  
})

app.use(store);
app.use(router);
app.use(i18n);
app.use(VueAxios, axios);
app.use(VueToast);
app.use(VueCookies)

// app.component('UserLayout', UserLayout);
app.mount('#app')
