import { createRouter, createWebHistory } from "vue-router";
import axios from 'axios'; // Import Axios at the top of your router file
import store from '../store/index.js'; // Import your Vuex store
import { useCookies } from 'vue3-cookies';

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/sub-category/:id",
    name: "Subcategory",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,

    },
    component: () => import("../views/subCat.vue"),
  },
  {
    path: "/category/:id",
    name: "category",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,

    },
    component: () => import("../views/cat.vue"),
  },
  {
    path: "/my-orders",
    name: "Myorders",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,

    },
    component: () => import("../views/myorder.vue"),
  },
  {
    path: "/my-order",
    name: "myorder",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,

    },
    component: () => import("../views/cat.vue"),
  },
  {
    path: "/security",
    name: "security",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,
    },
    component: () => import("../views/security.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,
    },
    component: () => import("../views/profile.vue"),
  },
  {
    path: "/my-wallet",
    name: "MyWallet",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,
    },
    component: () => import("../views/myWallet.vue"),
  },
  {
    path: "/add-credit",
    name: "AddCredit",
    meta: {
      layout: "UserLayout",
      requiresAuth: true,
    },
    component: () => import("../views/addCredit.vue"),
  },
  
  
  
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/register.vue"),
  },
  {
    path: "/otp",
    name: "RegOtp",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/otp.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/about.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    meta: {
      layout: "UserLayout",
    },
    component: () => import("../views/contactus.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const cookies = useCookies();

router.beforeEach((to, from, next) => {
  // if (this.$cookies.isKey("_FCu"))
  const hasCookieCurrency = document.cookie.includes('_FCsc=');
  const hasCookie = document.cookie.includes('_FCu=');
  const allCookies = document.cookie.split(';');
  let _FCsc=null;
  let _FCat = null;
  for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i].trim();
      if (cookie.startsWith('_FCat=')) {
          _FCat = cookie.substring('_FCat='.length);
          break;
      }
  }
  if(hasCookieCurrency){
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i].trim();
      if (cookie.startsWith('_FCsc=')) {
          _FCsc = cookie.substring('_FCsc='.length);
          break;
      }
    }
    const decodedJsonString = decodeURIComponent(_FCsc);
    const parsedData = JSON.parse(decodedJsonString);
    store.state.selectedCurrency=parsedData
    store.state.selectedCurrencyRate=parsedData.rate
    store.state.selectedCurrencyCode=parsedData.code
  }
  
  const userAuthenticated = (_FCat !== null &&  hasCookie);
  store.state.loader=1;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //Need Auth and the user not authed
  if (requiresAuth && (!userAuthenticated)) {
    next("/login");
  }
  // Need auth and the user is authed
  else if (requiresAuth && (userAuthenticated) ) {
    store.state.isAuth=true;
    store.state.loader=0;
    next();
  }
  // Dose not need auth
  else {
    if(userAuthenticated){
        store.state.loader=0;
        store.state.isAuth=true;
        next();
      }
      else{
        store.state.isAuth=false;
        store.state.loader=0;
        next();
      }
    }
});

export default router;